import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import localforage from "localforage";
import API, {getLanguageUrl, getSkillUrl, getUserUrl} from "../utils/api";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import UserListDefault from "./UserListDefault2";
import {styled} from "@mui/material/styles";

const TypographyX = styled(Typography)(({theme}) => ({
	fontWeight: 'bold', mr: '10px', fontSize: '36px', display: 'inline-block',
	'@media (max-width: 900px)': {
		fontSize: '24px',
	},
}));

const Breadcrumb = ({a, b}) => {
	return <Grid sx={{marginTop: '50px'}}>
		<Link style={{textDecoration: 'none'}} to="/homepage">
			<Typography component={'span'}
						sx={{color: '#ABABAB', fontSize: '12px', fontFamily: 'Montserrat'}}>Home</Typography>
		</Link>
		<Link style={{textDecoration: 'none'}} to={`/user-list/${a}/${b}`}>
			<Typography component={'span'}
						sx={{color: '#ABABAB', fontSize: '12px', fontFamily: 'Montserrat'}}>/{a}</Typography>
		</Link>
		<Link style={{textDecoration: 'none'}} to={`/user-list/${a}/${b}`}>
			<Typography component={'span'}
						sx={{
							color: '#484848', fontSize: '12px', fontFamily: 'Montserrat', fontWeight: '500'
						}}>/{b}</Typography>
		</Link>
	</Grid>
}

const UserContent = (props) => {
	const [isLoading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const {type, search} = props;
	const history = useHistory();

	useEffect(() => {
		getUsers();
	}, [])

	const getUsers = async () => {
		const token = await localforage.getItem('token');
		let requestURl = API.getAllFreelancers;
		let req1, req2, req3;
		let result = [];

		if (search?.length > 0) {
			req1 = await axios.get(getLanguageUrl(search), {
				headers: {
					'Authorization': token
				}
			}).catch((e) => {
				if (e?.response?.status === 401) {
					localforage.removeItem('token');
					history.push("/");
				}
			})
			req2 = await axios.get(getSkillUrl(search), {
				headers: {
					'Authorization': token
				}
			});
			req3 = await axios.get(getUserUrl(search), {
				headers: {
					'Authorization': token
				}
			});
		} else {
			req1 = await axios.get(requestURl, {
				headers: {
					'Authorization': token
				}
			});
		}

		if (req1 && req1.status === 200) {
			result = result.concat(req1.data)
		}
		if (req2 && req2?.status === 200) {
			result = result.concat(req2.data)
		}
		if (req3 && req3?.status === 200) {
			result = result.concat(req3.data)
		}
		result = result?.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
		setUsers(result)
		setLoading(false)
	}

	return (<Grid container>
		<Breadcrumb a={type} b={search}/>
		<Grid item sx={{width: '100%'}}>
			<Grid container sx={{paddingTop: '20px'}} justifyContent={'space-between'}>
				<Grid item>
					<TypographyX component="span">
						{search}
					</TypographyX>
				</Grid>
			</Grid>
		</Grid>
		<Grid xs={12} container>
			{isLoading ? <Box
				sx={{width: '100%', marginTop: '100px'}}
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress/>
			</Box> : <UserListDefault users={users}/>}
		</Grid>
	</Grid>)
}

export default UserContent;
