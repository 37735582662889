import {Box, Grid, Typography} from "@mui/material";
import UserCard from "./UserCard";
import {styled} from "@mui/material/styles";

const ExtendedGrid = styled(Grid)(({theme}) => ({
	width: '100%', paddingRight: '30px', paddingLeft: '30px', '@media (max-width: 550px)': {
		paddingLeft: 0, paddingRight: 0
	},
}));

const UserList = (props) => {
	return <ExtendedGrid>
		{props.users.length > 0 && props.users?.map((user, index) => {
			return (<>
				<Grid key={'t' + index} container sx={{width: '100%', marginBottom: '30px'}}>
					<Grid item xs={12}> <Typography
						sx={{fontWeight: '700', fontSize: '32px'}}>{user?.name}</Typography></Grid>
					{user.users?.map(($user, $index) => {
						return <Grid item key={'userlist' + $index} xs={12} sm={6} md={4} lg={2.4} xl={3}>
							<Box sx={{display: 'flex'}}>
								<UserCard user={$user}/>
							</Box>
						</Grid>
					})}
				</Grid>
			</>)
		})}
	</ExtendedGrid>
}

export default UserList;
