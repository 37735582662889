import {createTheme} from "@mui/material";

const theme = createTheme({
	typography: {
		fontFamily: [
			'Nunito',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
});


export default theme;
