import {Grid} from "@mui/material";
import HeaderContent from "../components/HeaderContent";
import UserContent from "../components/UserContent";
import {useParams} from "react-router-dom";
import React from "react";
import MainRow from "../components/MainRow";
import Footer from "../components/FooterStatic";


const UserListPage = () => {
	let {type, search} = useParams();
	return <Grid  container>
		<HeaderContent/>
		<Grid xs={12}>
			<MainRow>
				<UserContent type={type} search={search}/>
			</MainRow>
		</Grid>
	</Grid>
}
export default UserListPage;
