import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useHistory} from "react-router-dom";
import Person2Icon from '@mui/icons-material/Person2';
import RemoveIcon from '@mui/icons-material/Remove';

export default function SwipeableTemporaryDrawer({state, setState, menuList}) {
	const history = useHistory();

	const toggleDrawer = (anchor, open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({...state, [anchor]: open});
	};

	let skills = []
	for (const menu of menuList) {
		for (const data of menu?.attributes?.skills?.data) {
			skills.push(data?.attributes?.title)
		}
	}

	const redirectUserAccount = () => {
		history.push('user-account')
	}


	const openPage = (skill) => {
		history.push(`/user-list/${skill}`);
	}

	const list = (anchor) => (<Box
		sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
		role="presentation"
		onClick={toggleDrawer(anchor, false)}
		onKeyDown={toggleDrawer(anchor, false)}
	>
		<List>
			{skills?.map((skill, index) => {
				return (<><ListItem key={index} disablePadding onClick={()=>openPage(skill)}>
					<ListItemButton>
						<ListItemIcon>
					 <ArrowRightIcon/>
						</ListItemIcon>
						<ListItemText primary={skill}/>
					</ListItemButton>
				</ListItem>		<Divider/>
				</>)
			})}
			<Divider />
			<ListItem key={'profile'} disablePadding onClick={redirectUserAccount}>
				<ListItemButton>
					<ListItemIcon>
						<Person2Icon/>
					</ListItemIcon>
					<ListItemText primary={'My Profile'}/>
				</ListItemButton>
			</ListItem>
			</List>
		<Divider/>

	</Box>);

	return (<div>
		{['right'].map((anchor) => (<React.Fragment key={anchor}>
			<SwipeableDrawer
				anchor={anchor}
				open={state[anchor]}
				onClose={toggleDrawer(anchor, false)}
				onOpen={toggleDrawer(anchor, true)}
			>
				{list(anchor)}
			</SwipeableDrawer>
		</React.Fragment>))}
	</div>);
}
