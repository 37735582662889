import {
	Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Typography
} from "@mui/material";
import localforage from "localforage";
import API, {getUsersByFilter} from "../utils/api";
import {toast} from "react-toastify";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import UserListDefault from "./UserListDefault";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {styled} from "@mui/material/styles";
import MainRow from "./MainRow";


const Filter = styled(Box)(({theme}) => ({
	position: 'absolute',
	top: '50px',
	left: '-150px',
	width: '184px',
	zIndex: 999,
	backgroundColor: 'white',
	boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.13)',
	padding: '22px',
	maxHeight: '620px',
	overflowY: 'scroll'
}));

const CustomSelect = styled(Select)(({theme}) => ({
	height: '30px', borderRadius: '5px', border: '1px solid #E9E9E9', '&:active, &:hover, &:focus': {
		border: '1px solid #E9E9E9',
	}, '& fieldset': {
		display: 'none'
	}
}));

const Breadcrumb = ({a, b}) => {
	return <Grid sx={{ marginTop: '50px'}}>
		<Link style={{textDecoration: 'none'}} to="/homepage">
			<Typography component={'span'}
						sx={{color: '#ABABAB', fontSize: '12px', fontFamily: 'Montserrat'}}>Home</Typography>
		</Link>
		<Link style={{textDecoration: 'none'}} to={`/all`}>
			<Typography component={'span'}
						sx={{
							color: '#484848', fontSize: '12px', fontFamily: 'Montserrat', fontWeight: '500'
						}}>/All</Typography>
		</Link>
	</Grid>
}

const UserContent = (props) => {
	const [users, setUsers] = useState([]);
	const {type, search} = props;
	const [isOpen, setOpen] = useState(false);
	const [isFilterChanged, setIsFilterChanged] = React.useState(false)
	const [filter, setFilter] = useState({
		skills: [], languages: [], ndd: null, badges: []
	});
	const [languageList, setLanguageList] = useState([]);
	const [skillsList, setSkillsList] = useState([]);
	const [sortingValue, setSortingValue] = useState('');
	const [searchItems, setSearchItems] = useState(null);
	const [badgeList, setBadgeList] = useState([]);
	const history = useHistory();

	useEffect(() => {
		getLanguageList();
		getBadgeList();
		getSkillsListFromServer();
	}, [])


	useEffect(() => {
		getUsers();
	}, [searchItems])

	const getLanguageList = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast.error('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.languages, {
			headers: {
				'Authorization': token
			}
		}).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})

		if (response.status === 200) {
			setLanguageList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	const getSkillsListFromServer = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.skills, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setSkillsList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	const getBadgeList = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.badges, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setBadgeList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}


	const getUsers = async (sorting) => {
		const token = await localforage.getItem('token');
		let requestURl = getUsersByFilter(searchItems, sorting || sortingValue);
		if (!token) {
			toast.error('Please login the Jelly');
			history.push("/");
			return false;
		}
		const response = await axios.get(requestURl, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			let groups = {};
			for (const user of response.data) {
				for (const userRates of user?.rates) {
					const userSkillTitle = userRates?.skill?.title
					let groupSkill = groups[userSkillTitle]
					if (!groupSkill) {
						groups[userSkillTitle] = {
							name: userSkillTitle, users: [user]
						}
					} else {
						let $users = groupSkill.users;
						$users.push(user)
						groups[userSkillTitle].users = $users
					}
				}
			}
			setUsers(Object.values(groups))
		} else {
			toast.error('An error occurred')
		}
	}

	const onChangeFilter = (_type, name, value) => {
		let _filter = filter;
		let _skills = filter?.skills;
		let _languages = filter?.languages;
		let _badges = filter?.badges;

		if (_type === 'skill') {
			if (!value) {
				_skills = _skills.filter((sk) => sk !== name)
			} else {
				if (!_skills.includes(name)) {
					_skills.push(name)
				}
			}
			_filter.skills = _skills;
		} else if (_type === 'language') {
			if (!value) {
				_languages = _languages.filter((sk) => sk !== name)
			} else {
				if (!_languages.includes(name)) {
					_languages.push(name)
				}
			}
			_filter.languages = _languages;
		} else if (_type === 'badge') {
			if (!value) {
				_badges = _badges.filter((sk) => sk !== name)
			} else {
				if (!_badges.includes(name)) {
					_badges.push(name)
				}
			}
			_filter.badges = _badges;
		} else if (_type === 'ndd') {
			_filter.ndd = value;
		}
		setFilter(_filter)
		setIsFilterChanged(!isFilterChanged)
		setTimeout(() => {
			let total = '';
			let skills = ''
			let languages = ''
			let badges = ''
			let ndd = `&filters[ndd][$eq]=${filter.ndd}`
			filter?.skills?.map(($filter) => {
				skills += `&filters[skills][id][$in]=${$filter}`
			})
			filter?.badges?.map(($badge) => {
				badges += `&filters[badges][id][$in]=${$badge}`
			})
			filter?.languages?.map(($language) => {
				languages += `&filters[languages][id][$in]=${$language}`
			})
			total = total + ndd + skills + languages + badges;
			setSearchItems(total)
		}, 500)
	}

	return (<Grid container>
		<Breadcrumb a={type} b={search}/>
		<Grid item sx={{width: '100%'}}>
			<Grid container sx={{padding: '20px'}} justifyContent={'space-between'}>
				<Grid item>
					<Typography component="span"
								sx={{fontWeight: 'bold', mr: '10px', fontSize: '36px', display: 'inline-block'}}>
						{search}
					</Typography>
				</Grid>
				<Grid item sx={{
					flexDirection: 'row', display: 'flex', paddingRight: '35px', cursor: 'pointer', position: 'relative'
				}}>
					<FilterAltIcon onClick={() => setOpen(!isOpen)}
								   sx={{position: 'relative', color: '#44A3B5', top: '5px'}}/>
					<Typography
						onClick={() => setOpen(!isOpen)}
						sx={{color: '#44A3B5', fontSize: '18px', position: 'relative', top: '3px'}}>Filter</Typography>

					{isOpen && <Filter className={'hide-scrollbar'}>
						<Typography sx={{fontWeight: '500', marginBottom: '20px'}}>Filter by</Typography>

						{skillsList?.map((skill) => {
							return (<FormGroup sx={{height: '30px'}}>
								<FormControlLabel sx={{fontSize: '12px', color: '#696969'}} control={<Checkbox
									checked={filter?.skills?.includes(skill.id)}
									sx={{
										color: '#E9E9E9', '&.Mui-checked': {
											color: '#44A3B5',
										},
									}} onChange={(e) => onChangeFilter('skill', skill.id, e.target.checked)}/>}
												  label={skill?.attributes?.title}/>
							</FormGroup>)
						})}

						<Box>
							<FormGroup sx={{height: '30px'}}>
								<FormControlLabel sx={{fontSize: '12px', color: '#696969'}}
												  control={<Checkbox disabled={true} sx={{
													  color: '#E9E9E9', '&.Mui-checked': {
														  color: '#44A3B5',
													  },
												  }}
												  />} label="Language"/>
							</FormGroup>
							<Box sx={{paddingLeft: '30px'}}>
								{languageList?.map((language) => {
									return (<FormGroup sx={{height: '30px'}}>
										<FormControlLabel sx={{fontSize: '12px', color: '#696969'}}
														  control={<Checkbox
															  checked={filter?.languages?.includes(language.id)}
															  sx={{
																  color: '#E9E9E9', '&.Mui-checked': {
																	  color: '#44A3B5',
																  },
															  }}
															  onChange={(e) => onChangeFilter('language', language.id, e.target.checked)}
														  />} label={language?.attributes?.title}/>
									</FormGroup>)
								})}
							</Box>
						</Box>

						<Box sx={{borderBottom: '1px solid #E9E9E9', marginTop: '17px', marginBottom: '17px'}}/>

						<FormGroup sx={{height: '30px'}}>
							<FormControlLabel sx={{fontSize: '12px', color: '#696969'}} control={<Checkbox
								checked={filter?.ndd}
								sx={{
									color: '#E9E9E9', '&.Mui-checked': {
										color: '#44A3B5',
									},
								}}
								onChange={(e) => onChangeFilter('ndd', 'ndd', e.target.checked)}
							/>} label="NDD"/>
						</FormGroup>

						<Box sx={{borderBottom: '1px solid #E9E9E9', marginTop: '17px', marginBottom: '17px'}}/>

						{badgeList?.map((badge) => {
							return (<FormGroup sx={{height: '30px'}}>
								<FormControlLabel sx={{fontSize: '12px', color: '#696969'}} control={<Checkbox
									checked={filter?.badges?.includes(badge.id)}
									sx={{
										color: '#E9E9E9', '&.Mui-checked': {
											color: '#44A3B5',
										},
									}}
									onChange={(e) => onChangeFilter('badge', badge.id, e.target.checked)}
								/>} label={badge?.attributes?.title}/>
							</FormGroup>)
						})}

						<Box sx={{borderBottom: '1px solid #E9E9E9', marginTop: '17px', marginBottom: '17px'}}/>

						<Typography sx={{fontWeight: '500', marginBottom: '20px'}}>Sort by</Typography>

						<FormControl fullWidth>
							<CustomSelect
								id="demo-simple-select"
								value={sortingValue}
								label="Format"
								onChange={(e) => {
									setSortingValue(e.target.value)
									getUsers(e.target.value);
								}}>
								<MenuItem value={'rate'}>Rate</MenuItem>
							</CustomSelect>
						</FormControl>
					</Filter>}
				</Grid>
			</Grid>
		</Grid>
		<Grid xs={12} container>
			<UserListDefault users={users}/>
		</Grid>
	</Grid>)
}

export default UserContent;
