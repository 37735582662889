import HeaderContent from "../components/HeaderContent";
import {Grid, Typography} from "@mui/material";
import AccountCard from "../components/Account/AccountCard";
import UserPrivacyInfo from "../components/Account/UserPrivacyInfo";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import localforage from "localforage";
import axios from "axios";
import API from "../utils/api";
import {toast} from "react-toastify";
import MainRow from "../components/MainRow";
import Footer from "../components/FooterStatic";

const Breadcrumb = () => {
    return <Grid sx={{paddingLeft: '100px', marginTop: '50px'}}>
        <Link style={{textDecoration: 'none'}} to="/homepage">
            <Typography component={'span'}
                        sx={{color: '#ABABAB', fontSize: '12px', fontFamily: 'Montserrat'}}>Home</Typography>
        </Link>
        <Link style={{textDecoration: 'none'}} to="/user-privacy">
            <Typography component={'span'}
                        sx={{color: '#484848', fontSize: '12px', fontFamily: 'Montserrat', fontWeight: '500'}}>/Privacy</Typography>
        </Link>
    </Grid>
}


const UserPrivacy = () => {
    const [user, setUser] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getUserDataFromServer()
    }, []);

    const getUserDataFromServer = async () => {
        const token = await localforage.getItem('token');
        const userStr = await localforage.getItem('user');
        if (!token) {
            toast('lütfen login olunuz.');
            history.push("/");
            return false;
        }
        const email = JSON.parse(userStr.toString()).email;
        axios.get(API.getMyUserDetail + email, {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            setUser(response.data[0]);
        }).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})
    }
    return <>
        <Grid container>
            <Grid item xs={12}>
                <HeaderContent/>
            </Grid>
        </Grid>
		<MainRow>
			<Grid container>
				<Grid item>
					<Breadcrumb/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item  xs={12} md={4} lg={3}>
					<AccountCard user={user}/>
				</Grid>
				<Grid item xs={12} md={8} lg={9}>
					<UserPrivacyInfo/>
				</Grid>
			</Grid>
		</MainRow>
		<Footer/>
    </>
}

export default UserPrivacy;
