import UserLogin from "../components/UserLogin";
import LoginImage from "../components/LoginImage";
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import API from "../utils/api";
import localforage from "localforage";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";


const LoginPage = () => {
	const history = useHistory();
	const [welcome, setWelcome] = useState(null);

	useEffect(() => {
		getLoginPage()
		checkActiveToken()
	}, []);

	const getLoginPage = async () => {
		const response = await axios.get(API.welcome);
		if (response.status === 200) {
			setWelcome(response.data.data[0]);
		} else {
			toast.error("Email or password is incorrect")
		}
	}

	const checkActiveToken = async () => {
		const token = await localforage.getItem('token');
		if (token) {
			history.push("/homepage");
		}
	}



	return <Grid container>
		<Grid item xs={12} lg={6}>
			<LoginImage welcome={welcome}/>
		</Grid>
		<Grid item xs={12} lg={6}>
			<UserLogin welcome={welcome}/>
		</Grid>
	</Grid>
}

export default LoginPage;
