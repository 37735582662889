import {Box} from "@mui/material";
import getCdnImage from "../utils/image";

const LoginImage = (props) => {
	if (!props?.welcome?.attributes?.image?.data?.attributes?.hash) {
		return null
	}
	return <Box>
		<img width={'100%'} src={getCdnImage(props?.welcome?.attributes?.image?.data?.attributes?.hash,
			props?.welcome?.attributes?.image?.data?.attributes?.ext)} alt="homepage"/>
	</Box>
}
export default LoginImage;
