import {Box, Collapse, IconButton, Typography} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useState} from "react";
import getCdnImage from "../utils/image";
import {Link} from "react-router-dom";
import {KeyboardArrowRight} from "@mui/icons-material";

const TableTag = styled(TableCell)(({theme}) => ({
	color: '#44A3B5', backgroundColor: '#FCFCFC', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500'
}));

export const TableHeader = styled(Typography)(({theme}) => ({
	fontSize: '24px', lineHeight: '49px', fontFamily: 'Montserrat', fontWeight: '500', color: '#000000',

}));
const TableBox = styled(TableContainer)(({theme}) => ({
	boxShadow: 'none', background: '#FCFCFC', display: 'flex', width: '100%', borderRadius: '20px',

}));

const MyTableCell = styled(TableCell)(({theme}) => ({
	paddingTop: '30px', paddingBottom: '30px', color:'#484848'
}));

const BoxX = styled(Box)(({theme}) => ({
	margin: 1,
	display: 'flex',
	flexDirection: 'row',
	paddingLeft: '60px',
	paddingRight: '60px',
	paddingBottom: '40px',
	paddingTop: '20px',
	'@media (max-width: 900px)': {
		paddingLeft: '15px',
		paddingRight: '15px',
	},
}));

const UserWork = (props) => {
	const {user} = props
	const [open, setOpen] = useState(-1);

	const getFormattedDate = (date) => {
		const tableDate = new Date(date);
		return `${tableDate.getDay()}.${tableDate.getMonth() + 1}.${tableDate.getFullYear()}`
	}

	return (<Box>
		<TableHeader component={'h2'} mt={'85px'} mb={'2px'}>Previous Works</TableHeader>
		<TableBox className={'tablex'} component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableTag align="center">Date</TableTag>
						<TableTag align="center">Task</TableTag>
						<TableTag align="center">Language</TableTag>
						<TableTag align="center">Status</TableTag>
						<TableTag align="center">Rate</TableTag>
					</TableRow>
				</TableHead>
				<TableBody>
					{user?.task?.length > 0 && user.task.map((row, index) => {
						return (<>
							<TableRow
								key={'works' + index}
								sx={{'&:last-child td, &:last-child th': {border: '5px'}}}>
								<MyTableCell align="center">{getFormattedDate(row.date)}</MyTableCell>
								<MyTableCell align="center">{row?.skill?.title}</MyTableCell>
								<MyTableCell align="center">{row?.language?.title}</MyTableCell>
								<MyTableCell sx={{color: row.status?.toLowerCase() === 'open' ? '#DF8E2F' : '#69B10C'}}
											 align="center">{row.status}</MyTableCell>
								<MyTableCell align="center">{row.rate}/5</MyTableCell>
								<IconButton
									sx={{position: 'relative', top: '24px'}}
									aria-label="expand row"
									size="small"
									onClick={() => {
										if (open !== index) {
											setOpen(index)
										} else {
											setOpen(999)
										}
									}}
								>
									{open ? <KeyboardArrowRight/> : <KeyboardArrowDownIcon/>}
								</IconButton>
							</TableRow>
							{row?.comment_txt && <TableRow>
								<TableCell style={{paddingBottom: 0, paddingTop: 0, border: 1}} colSpan={6}>
									<Collapse in={open === index} timeout="auto" unmountOnExit>
										<BoxX>
											<Box sx={{
												paddingRight: '50px',
												borderRight: '1px solid #EDEDED',
												marginRight: '50px'
											}}>
												<Typography sx={{
													fontFamily: 'Montserrat',
													fontSize: '16px',
													fontWeight: '900',
													color: '#484848'
												}} gutterBottom component="div">
													Preview
												</Typography>
												<Link target="_blank" download to={{pathname: row?.preview?.url}}>
													<Box component={'img'} width={58} src={'/images/preview.jpg'}/>
												</Link>
											</Box>
											<Box>
												<Typography sx={{
													fontFamily: 'Montserrat',
													fontSize: '16px',
													fontWeight: '900',
													color: '#484848',
													paddingBottom: '15px'
												}} gutterBottom component="div">
													Comment
												</Typography>
												<Box sx={{flexDirection: 'row', display: 'flex'}}>
													<Box width={'45'} component={'img'}
														 sx={{borderRadius: '22px', width: '45px', height: '45px'}}
														 src={getCdnImage(row?.comment_img?.hash, row?.comment_img?.ext)}/>
													<Typography sx={{
														fontFamily: 'Montserrat',
														fontSize: '14px',
														fontWeight: '400',
														paddingLeft: '20px'
													}}>
														{row?.comment_txt}
													</Typography>
												</Box>
											</Box>
										</BoxX>
									</Collapse>
								</TableCell>
							</TableRow>}

						</>)
					})}
				</TableBody>
			</Table>
		</TableBox>
	</Box>);
}

export default UserWork;
