import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import React, {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import localforage from "localforage";
import {styled} from "@mui/material/styles";
import API from "../utils/api";
import validateEmail from "../utils/email";
import JellyButton from "./JellyButton";

const LoginTextField = styled(TextField)(({theme}) => ({
	'@media (max-width: 550px)': {
		marginTop: '20px'
	}, ' & fieldset': {
		border: '1px solid #44A3B5', boxSizing: 'border-box', borderRadius: '15px', fontFamily:'Avenir'
	}
}));

export const LoginBox = styled(Box)(({theme}) => ({
	padding: '15px',
	marginTop: '100px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	borderRadius: '10px',
	'@media (max-width: 550px)': {
		marginTop: '20px'
	}
}));

const UserLogin = (props) => {

	const {welcome} = props;
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const clickHandler = () => {
		if (!validateEmail(email.trim())) {
			toast.error('Please enter a valid e-mail')
			return false;
		}
		if (password.length < 6) {
			toast.error('lütfen password giriniz.')
			return false;
		}
		makeLoginRequest()
	}

	const myUserFromServer = async (token) => {
		const response = await axios.get(API.getMyUserDetail + email, {
			headers: {
				'Authorization': token
			}
		})
		if (response.status === 200) {
			await localforage.setItem('user', JSON.stringify(response.data[0]))
		} else {
			toast.error('An error occurred')
		}
	}

	const makeLoginRequest = () => {
		axios.post(API.login, {
			identifier: email.trim(), password: password
		}).then(async (response) => {
			if (response.status === 200) {
				const token = "Bearer " + response.data.jwt;
				await localforage.setItem('token', token)
				await myUserFromServer(token);
				history.push("/homepage");
			} else {
				toast.error('Email or password is incorrect')
			}
		}).catch(() => {
			toast.error('Email or password is incorrect')
		})
	}

	const emailChanged = (event) => {
		setEmail(event.target.value);
	}

	const passwordChanged = (event) => {
		setPassword(event.target.value)
	}

	return (<LoginBox>
		<Box component="div" noValidate sx={{mt: 1}}>
			<Typography component="h1" variant="h3"
						sx={{fontFamily: 'Lilita One', fontWeight: 'normal', textAlign: 'left'}}>
				{welcome?.attributes?.title}
			</Typography>
			<p> {welcome?.attributes?.description} </p>

			<LoginTextField
				margin="normal"
				required
				fullWidth
				id="email"
				value={email}
				onChange={emailChanged}
				label="E-mail"
				name="email"
				autoComplete="email"
				autoFocus
			/>
			<LoginTextField
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				value={password}
				onChange={passwordChanged}
				type="password"
				id="password"
				autoComplete="current-password"
			/>
			<JellyButton
				type="button"
				fullWidth
				onClick={clickHandler}
				variant="contained"
				sx={{mt: 3, mb: 2}}>
				Login
			</JellyButton>
			<Grid container justifyContent={'flex-end'}>
				<Grid item>
					<Link style={{textDecoration: 'none'}} to="/forget-password">
						<Typography component={'span'} sx={{color: '#696969',fontSize:'16px'}}>Forgot my password?</Typography>
					</Link>
				</Grid>
			</Grid>
		</Box>
	</LoginBox>)
}
export default UserLogin;
