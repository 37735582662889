import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import JellyButton from "../JellyButton";
import {Link} from "react-router-dom";


export const UserInfoCard = styled(Card)(({theme}) => ({
    marginTop: 50, padding: '50px', marginLeft: '50px',  '@media (max-width: 550px)': {
        padding: '15px', marginLeft: '0', marginRight: '0', borderRadius: '20px',
    }
}));


export const MyTextField = styled(TextField)(({theme}) => ({
    width: '90%', marginRight: '40px', marginBottom: '10px', '& input': {
        width: '100%', backgroundColor: 'white', fontFamily: 'Montserrat', fontSize: '14px',
    }, '@media (max-width: 550px)': {
        width: '80%',
    }
}));

export const FooterWrapper = styled(Grid)(({theme}) => ({
    position: 'relative', top: '30px', right: '50px', '@media (max-width: 550px)': {
        position: 'static'
    }
}));

export const HeaderTxt = styled(Grid)(({theme}) => ({
    fontSize: 20, fontWeight: 500, '@media (max-width: 550px)': {
        marginTop: '30px'
    }
}));


const UserInfo = (props) => {
    const [currentUser, setCurrentUser] = useState(props.user);

    useEffect(() => {
        setCurrentUser(props.user)
    }, [props.user]);

    const onSaveHandler = () => {
        props.saveUser(currentUser)
    }

    const onNameChanged = (e) => {
        const data = e.target.value;
        setCurrentUser({...currentUser, name: data})
    }

    const onSurnameChange = (e) => {
        const data = e.target.value;
        setCurrentUser({...currentUser, surname: data})
    }

    const onEmailChange = (e) => {
        const data = e.target.value;
        setCurrentUser({...currentUser, email: data})
    }

    const onPhoneChange = (e) => {
        const data = e.target.value;
        setCurrentUser({...currentUser, phone: data})
    }

    return (<UserInfoCard sx={{borderRadius: '20px', boxShadow: 'none', background: '#F8F8F8', minHeight: '400px'}}>

            <HeaderTxt mb={'20px'}>Basic Info</HeaderTxt>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <MyTextField value={currentUser?.name} onChange={onNameChanged}
                                 variant="outlined">Name</MyTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MyTextField value={currentUser?.surname} onChange={onSurnameChange}
                                 variant="outlined">Surname</MyTextField>
                </Grid>
            </Grid>

            <Typography sx={{fontSize: 20, fontWeight: 500}} mt={'30px'} mb={'20px'}>Contact</Typography>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <MyTextField value={currentUser?.email} onChange={onEmailChange}
                                 variant="outlined">Email</MyTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MyTextField value={currentUser?.phone} onChange={onPhoneChange}
                                 variant="outlined">Phone</MyTextField>
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'} sx={{marginTop: {xs: '30px'}}}>
                <FooterWrapper item>
                    <JellyButton
                        sx={{width: '200px', borderRadius: '15px', fontFamily: 'Montserrat', fontSize: '16px'}}
                        type="button"
                        fullWidth
                        onClick={onSaveHandler}
                        variant="contained">
                        Update
                    </JellyButton>
                </FooterWrapper>
            </Grid>
        </UserInfoCard>

    );
}

export default UserInfo;
