import {Box, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";

const SearchTitle = styled(Typography)(({theme}) => ({
	fontSize: '18px', marginBottom: '15px'
}));

const SearchText = styled(Typography)(({theme}) => ({
	backgroundColor: '#147C90',
	color: 'white',
	padding: '5px 15px',
	display: 'inline-block',
	marginRight: '10px',
	borderRadius: '10px',
	cursor: 'pointer',
	fontSize: '12px',
	paddingTop: '7px',
	fontWeight: '500',
	paddingLeft: '10px',
	paddingRight: '7px',
	paddingBottom: '10px',
	marginBottom:'10px'
}));

const SearchWidget = (props) => {
	const {title, items, setSearchValue, setSearchEnabled, isRecent, showClear} = props;

	const onItemClick = (groupTitle, item) => {
		setSearchEnabled(false)
		setSearchValue({
			title: groupTitle, type: !isRecent ? title?.toLowerCase() : item?.title?.toLowerCase()
		});
	}

	const clearAndClose = () => {
		setSearchEnabled(false)
		setSearchValue({
			title: '', type: null
		})
	}

	return <Box mb={2}>
		<SearchTitle component="p"
					 sx={{display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row'}}>
			{title} {showClear && <Box onClick={clearAndClose} sx={{color: 'red'}} component={'span'}>Clear</Box>}
		</SearchTitle>
		<Box>
			{items?.length > 0 ? items?.map((item, index) => {
				return <SearchText key={'search' + index} onClick={() => onItemClick(item?.attributes?.title, item)}
								   component="span">{item?.attributes?.title}</SearchText>
			}) : <Typography component={"span"}>{title} not found</Typography>}
		</Box>
	</Box>
}

export default SearchWidget
