import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {MenuButton} from "./UserSearch";

const MenuX = styled(Menu)(({theme}) => ({
	boxShadow: 'none !important',
	'&>*': {
		boxShadow: 'none !important',
	}
}));


const MyMenu = ({menu, openPage}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (<div>
		<MenuButton
			id="basic-button"
			aria-controls={open ? 'basic-menu' : undefined}
			aria-haspopup="true"
			sx={{boxShadow: 'none'}}
			aria-expanded={open ? 'true' : undefined}
			onClick={handleClick}
			endIcon={<KeyboardArrowDownIcon sx={{marginLeft: '-8px'}}/>}
		>
			{menu?.attributes?.Title}
		</MenuButton>
		<MenuX
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			sx={{boxShadow: 'none'}}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			{menu?.attributes?.skills?.data?.map((skill) => {
				return <MenuItem
					sx={{boxShadow: 'none'}}
					onClick={() => openPage(skill)}>{skill?.attributes?.title}</MenuItem>
			})}
		</MenuX>
	</div>)
}

export default MyMenu;
