import {Box, Typography} from "@mui/material";
import {TableHeader} from "./UserWork";
import Slider from "react-slick";
import React from "react";
import getCdnImage from "../utils/image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export function SampleNextArrow(props) {
	const {style, onClick} = props;
	return (<div
		className={'next-btn-comment'}
		style={{...style, display: "block"}}
		onClick={onClick}>
		<ArrowForwardIosIcon fontSize={'large'}/>
	</div>);
}

export function SamplePrevArrow(props) {
	const {style, onClick} = props;
	return (<div
		className={'prev-btn-comment'}
		style={{...style, display: "block"}}
		onClick={onClick}>
		<ArrowBackIosIcon fontSize={'large'}/>
	</div>);
}

const Comments = ({user}) => {
	const count = user?.comments?.length > 1 ? 2 : user?.comments?.length;

	if (!user?.comments) {
		return null;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: count,
		slidesToScroll: count,
		nextArrow: <SampleNextArrow/>,
		prevArrow: <SamplePrevArrow/>,
		responsive: [{
			breakpoint: 1024, settings: {
				slidesToShow: count, slidesToScroll: count, infinite: true, dots: true,
			}
		}, {
			breakpoint: 600, settings: {
				slidesToShow: 6, slidesToScroll: 6, initialSlide: 0, nextArrow: null, prevArrow: null,
			}
		},]
	};

	return <Box sx={{marginTop: '80px'}}>
		<TableHeader component={'h2'} mb={'20px'}>Comments</TableHeader>


		<Box sx={{display: {xs: 'none', md: 'block'}}}>
			<Slider  {...settings}>
				{user?.comments?.length > 0 && user?.comments?.map((_user, index) => {
					return <Box sx={{
						border: '1px solid #E9E9E9',
						display: 'flex !important',
						flexDirection: 'row !important',
						backgroundColor: '#fcfcfc',
						padding: '24px',
						borderRadius: '15px',
						marginRight: '15px'
					}} component={'div'}
								key={'comments' + index}>
						<Box component={'img'} width={62} height={62}
							 sx={{borderRadius: '31px'}}
							 src={getCdnImage(_user?.image?.hash, _user?.image?.ext)}/>
						<Box sx={{marginLeft: '20px'}}>
							<Typography sx={{fontSize: 21, fontWeight: 900, fontFamily: 'Montserrat'}}
										mb={'5px'}>{_user?.name}</Typography>
							<Typography sx={{fontSize: 16, fontWeight: 400, fontFamily: 'Montserrat', color: '#696969'}}
										mb={'20px'}>{_user?.title}</Typography>
							<Typography
								sx={{
									fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat'
								}}>{_user?.comment}</Typography>
						</Box>
					</Box>
				})}
			</Slider>
		</Box>
		<Box sx={{display: {xs: 'block', md: 'none'}}}>
			{user?.comments?.length > 0 && user?.comments?.map((_user, index) => {
				return <Box sx={{
					border: '1px solid #E9E9E9',
					display: 'flex !important',
					flexDirection: 'row !important',
					backgroundColor: '#fcfcfc',
					padding: '24px',
					borderRadius: '15px',
					marginBottom:'20px',
					marginRight: '15px'
				}} component={'div'}
							key={'comments' + index}>
					<Box component={'img'} width={62} height={62}
						 sx={{borderRadius: '31px'}}
						 src={getCdnImage(_user?.image?.hash, _user?.image?.ext)}/>
					<Box sx={{marginLeft: '20px'}}>
						<Typography sx={{fontSize: 21, fontWeight: 900, fontFamily: 'Montserrat'}}
									mb={'5px'}>{_user?.name}</Typography>
						<Typography sx={{fontSize: 16, fontWeight: 400, fontFamily: 'Montserrat', color: '#696969'}}
									mb={'20px'}>{_user?.title}</Typography>
						<Typography
							sx={{
								fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat'
							}}>{_user?.comment}</Typography>
					</Box>
				</Box>
			})}
		</Box>
	</Box>
}


export default Comments
