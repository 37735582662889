export const DOMAIN = 'https://admin.juicy.sharpcake.com'

export const CDN_URL = 'https://d9klh4gjtk5iz.cloudfront.net/'

const API = {
    welcome: `${DOMAIN}/api/static-pages?filters[page][$eq]=login&populate=image`,
    forget: `${DOMAIN}/api/static-pages?filters[page][$eq]=forget-password&populate=image`,
    homepage: `${DOMAIN}/api/static-pages?filters[page][$eq]=homepage&populate=image`,
    login: `${DOMAIN}/api/auth/local`,
    forgetPassword: `${DOMAIN}/api/auth/forgot-password`,
    languages: `${DOMAIN}/api/languages`,
    skills: `${DOMAIN}/api/skills`,
	menus: `${DOMAIN}/api/menus?populate=skills`,
    badges: `${DOMAIN}/api/badges`,
    password: `${DOMAIN}/api/updatepassword`,
    getMyUserDetail: `${DOMAIN}/api/users?populate=profile&filters[email][$eq]=`,
    updateUser: `${DOMAIN}/api/users/`,
    userService: `${DOMAIN}/api/users`,
    getAllFreelancers: `${DOMAIN}/api/users?pagination[start]=0&pagination[limit]=4&populate=profile,rates,rates.skill,languages&filters[isFreelancer][$eq]=true`,
}


export const getUsersByFilter = (req, sortingValue) => {
    let result = `${DOMAIN}/api/users?populate=profile,rates,rates.skill,badges,languages&filters[isFreelancer][$eq]=true${req}`
    if (sortingValue) {
        result += `&sort[0]=${sortingValue}%3Aasc`
    }
    return result;
}

export const getLanguageUrl = (title) => {
    return `${DOMAIN}/api/users?populate=profile,rates,rates.skill,languages&filters[isFreelancer][$eq]=true&filters[languages][title][$contains]=${title}`
}

export const getSkillUrl = (title) => {
    return `${DOMAIN}/api/users?populate=profile,rates,rates.skill,languages&filters[isFreelancer][$eq]=true&filters[rates][skill][title][$contains]=${title}`
}

export const getUserUrl = (title) => {
    return `${DOMAIN}/api/users?populate=profile,rates,rates.skill,languages&filters[isFreelancer][$eq]=true&filters[name][$contains]=${title}`
}

export default API;
