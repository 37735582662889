import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

const JellyButton = styled(Button)(({theme}) => ({
    background: '#44A3B5',
    height: '40px',
    fontSize: '17px',
    borderRadius: 10,
    textTransform: 'none',
    boxShadow: 'none',
    ' &:hover': {
        backgroundColor: '#44A3B5',
        boxShadow: 'none'
    }
}));

export default JellyButton;
