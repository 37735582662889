import {Box, Grid} from "@mui/material";
import HeaderContent from "../components/HeaderContent";
import UserProfileCard from "../components/UserProfileCard";
import UserWork from "../components/UserWork";
import {styled} from "@mui/material/styles";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import localforage from "localforage";
import axios from "axios";
import {toast} from "react-toastify";
import {DOMAIN} from "../utils/api";
import Comments from "../components/Comments";
import MainRow from "../components/MainRow";
import Footer from "../components/FooterStatic";

const UserWorkContainer = styled(Box)(({theme}) => ({
	marginTop: '50px'
}));

const ExtendedGrid = styled(Grid)(({theme}) => ({
	'@media (max-width: 550px)': {
		paddingLeft: 0, paddingRight: 0
	},
}));

const GridX = styled(Grid)(({theme}) => ({
	padding: '20px', paddingLeft: 0, '@media (max-width: 550px)': {
		paddingRight: 0
	},
}));

const GridY = styled(Grid)(({theme}) => ({
	padding: '20px', '@media (max-width: 550px)': {
		paddingRight: 0,
		paddingLeft: 0,
	},
}));

const UserProfilePage = () => {
	let {userId} = useParams();
	const [user, setUser] = useState([]);
	const history = useHistory();

	useEffect(() => {
		getUserData();
	}, []);

	const getUserData = async () => {
		const token = await localforage.getItem('token');
		const response = await axios.get(`${DOMAIN}/api/users/${userId}?populate=profile,rates,rates.skill,languages,task,task.skill,task.language,comments,comments.image,task.comment_img,preview`, {
			headers: {
				'Authorization': token
			}
		}).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})
		if (response.status === 200) {
			setUser(response.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	return <>
		<Grid container>
			<Grid item xs={12}>
				<HeaderContent/>
			</Grid>
		</Grid>
		<MainRow>
			<ExtendedGrid container>
				<GridX item xs={12} sm={12} md={6} lg={4}>
					<UserProfileCard user={user}/>
				</GridX>
				<GridY item xs={12} sm={12} md={6} lg={8}>
					<UserWorkContainer>
						<UserWork user={user}/>
						<Comments user={user}/>
					</UserWorkContainer>
				</GridY>
			</ExtendedGrid>
		</MainRow>
		<Footer/>
	</>
}
export default UserProfilePage;
