import UserSearch from "../components/UserSearch";
import UserList from "../components/UserList";
import {Box, CircularProgress, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import localforage from "localforage";
import axios from "axios";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import SearchWidget from "../components/SearchWidget";
import API from "../utils/api";
import Footer from "../components/Footer";


const SearchBox = styled(Box)(({theme}) => ({
	width: '100%',
	height: '100%',
	position: 'fixed',
	backgroundColor: '#00000080',
	zIndex: 10,
	display: 'flex',
	justifyContent: 'center',
	'@media (max-width: 900px)': {
		// position: 'static',
	},
}));

const InnerSearchBox = styled(Box)(({theme}) => ({
	width: '850px',
	height: '340px',
	backgroundColor: 'white',
	borderRadius: '20px',
	padding: '20px',
	'@media (max-width: 900px)': {
		height: '520px',
	},
}));


const HomePage = () => {
	const [searchEnabled, setSearchEnabled] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [languageList, setLanguageList] = useState([]);
	const [skillsList, setSkillsList] = useState([]);
	const [menuList, setMenuList] = useState([]);
	const [homepageMessage, setHomepageMessage] = useState(null);
	const [latestSearchList, setLatestSearchList] = useState([]);
	const [users, setUsers] = useState([]);
	const innerSearchMt = document.getElementById('user-search')?.getBoundingClientRect().top + 70;
	const [searchValue, setSearchValue] = useState({
		title: '', type: null
	});

	const history = useHistory();

	useEffect(() => {
		getLanguageList();
		getSkillsListFromServer();
		getHomepageMessages();
		getLatestSearchDataFromDb();
		getMenuList();
		getUsers();

	}, []);

	useEffect(() => {
		if (searchValue?.title.length > 0) {
			history.push(`/user-list/${searchValue.title}`);
			addSelectionsToDb();
		}
	}, [searchValue.title])

	const handleKeyDown = (e, value) => {
		if (e.keyCode === 13) {
			setSearchValue(value);
		}
	}

	const addSelectionsToDb = async () => {
		const searchItemsString = await localforage.getItem('searchItems');
		let searchItems = null
		if (searchItemsString) {
			searchItems = JSON.parse(searchItemsString.toString());
		} else {
			searchItems = []
		}
		if (searchItems.filter((item) => item?.attributes?.title === searchValue.title)?.length === 0) {
			searchItems.unshift({
				attributes: {
					title: searchValue.title,
				}, title: searchValue.type
			})
			searchItems = searchItems.splice(0, 5);
			await localforage.setItem('searchItems', JSON.stringify(searchItems))
			setLatestSearchList(searchItems);
		}
	}

	const getUsers = async () => {
		const token = await localforage.getItem('token');
		let requestURl = API.getAllFreelancers;
		if (!token) {
			toast.error('Please login the Jelly');
			history.push("/");
			setLoading(false)
			return false;
		}
		const response = await axios.get(requestURl, {
			headers: {
				'Authorization': token
			}
		}).catch((e) => {
			setLoading(false)
		})
		setLoading(false)
		if (response.status === 200) {
			setUsers(response.data.splice(0, 8))
		} else {
			toast.error('An error occurred')
		}
	}


	const getLatestSearchDataFromDb = async () => {
		const searchItemsString = await localforage.getItem('searchItems');
		if (searchItemsString) {
			const searchItems = JSON.parse(searchItemsString.toString());
			setLatestSearchList(searchItems)
		}
	}

	const getHomepageMessages = async () => {
		const response = await axios.get(API.homepage);
		if (response.status === 200) {
			setHomepageMessage(response.data.data[0]);
		}
	}

	const getLanguageList = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast.error('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.languages, {
			headers: {
				'Authorization': token
			}
		}).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})

		if (response.status === 200) {
			setLanguageList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}


	const reCheck = async () => {
		setLoading(true)
		await getUsers();
	}

	const getSkillsListFromServer = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.skills, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setSkillsList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	const getMenuList = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.menus, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setMenuList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	return <Grid container sx={{paddingBottom: '50px'}}>
		<Grid item xs={12} lg={12}>
			<UserSearch setSearchValue={setSearchValue}
						handleKeyDown={handleKeyDown}
						skillsList={skillsList}
						menuList={menuList}
						homepageMessage={homepageMessage} setSearchEnabled={setSearchEnabled}/>
		</Grid>

		<Grid xs={12} lg={12}>
			<UserList users={users} reCheck={reCheck}/>
			{isLoading && <Box
				sx={{width: '100%', marginTop: '100px'}}
				display="flex"
				justifyContent="center"
				alignItems="center">
				<CircularProgress/>
			</Box>}
		</Grid>
		{searchEnabled && <SearchBox>
			<InnerSearchBox sx={{marginTop: `${innerSearchMt}px`}}>
				<SearchWidget showClear={true} setSearchValue={setSearchValue}
							  setSearchEnabled={setSearchEnabled}
							  isRecent={true}
							  items={latestSearchList} title={'Recent Searches'}/>
				<SearchWidget setSearchValue={setSearchValue} items={languageList}
							  setSearchEnabled={setSearchEnabled}
							  title={'Languages'}/>
				<SearchWidget setSearchValue={setSearchValue}
							  setSearchEnabled={setSearchEnabled}
							  items={skillsList} title={'Skills'}/>
			</InnerSearchBox>
		</SearchBox>}
		<Footer/>
	</Grid>
}
export default HomePage;
