import {Button, Card, Grid, TextField, Typography, Box} from "@mui/material";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {toast} from "react-toastify";
import localforage from "localforage";
import axios from "axios";
import JellyButton from "../JellyButton";
import {FooterWrapper, MyTextField, UserInfoCard} from "./UserInfo";
import API from "../../utils/api";


const UserInfoCardBox = styled(UserInfoCard)(({theme}) => ({
    boxShadow: 'none', background: '#F8F8F8', borderRadius: '20px',
}));
const MyTextFieldBox = styled(MyTextField)(({theme}) => ({
    background: '#FFFFFF', // border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '15px', '& input': {
        fontFamily: 'Montserrat', fontSize: '12px', color: '#A5A5A5', width: '90%', borderRadius: '15px'

    }, '& div': {
        border: 'none'
    }
}));

export const HeaderTxt = styled(Grid)(({theme}) => ({
    fontSize: 20, fontWeight: 500, '@media (max-width: 550px)': {
        marginTop: '30px'
    }
}));


const UserPrivacyInfo = () => {
    const [oldPassword, setOldPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);

    const handleReset = () => {
        if (password.length < 6) {
            toast.error('Your password should be longer than 5 characters')
            return false;
        }
        if (password !== password2) {
            toast.error('Your passwords do not match')
            return false;
        }
        sendResetRequestToServer();
    }

    const sendResetRequestToServer = async () => {
        const token = await localforage.getItem('token');
        const userStr = await localforage.getItem('user');
        const email = JSON.parse(userStr.toString()).email;
        axios.post(API.password, {
            "identifier": email, "password": oldPassword, "newPassword": password
        }, {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            const _token = "Bearer " + response?.data?.jwt;
            localforage.setItem('token', _token)
            toast.success('Your password has been changed');
            setPassword('')
            setPassword2('')
            setOldPassword('')
        }).catch((e) => {
            toast.error('Please check your old password');
        })
    }

    return (<UserInfoCardBox>
        <HeaderTxt mb={'20px'}>Privacy</HeaderTxt>
        <Grid container>
            <Grid item xs={12} md={6}>
                <MyTextFieldBox component={'span'} type="password" onChange={(e) => setPassword(e.target.value)}
                                label="New Password" value={password} variant="outlined">Password</MyTextFieldBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <MyTextFieldBox sx={{borderRadius: '15px'}} component={'span'} type="password"
                                onChange={(e) => setPassword2(e.target.value)}
                                label="Confirm New Password" value={password2} variant="outlined">Confirm
                    Password</MyTextFieldBox>
            </Grid>
        </Grid>

        <Grid container justifyContent={'flex-end'}>
            <FooterWrapper item>
                <JellyButton
                    sx={{width: '200px'}}
                    type="button"
                    fullWidth
                    onClick={handleReset}
                    variant="contained">
                    Update
                </JellyButton>
            </FooterWrapper>
        </Grid>
    </UserInfoCardBox>);
}

export default UserPrivacyInfo;
