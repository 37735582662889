import './App.css';
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import UserListPage from "./pages/UserListPage";
import UserProfilePage from "./pages/UserProfilePage";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import UserAccount from "./pages/UserAccount";
import UserPrivacy from "./pages/UserPrivacy";
import ForgetPassword from "./pages/ForgetPassword";
import Talents from "./pages/Talents";

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/homepage">
					<HomePage/>
				</Route>
				<Route path="/forget-password">
					<ForgetPassword/>
				</Route>
				<Route path="/user-list/:search">
					<UserListPage/>
				</Route>
				<Route path="/user-profile/:userId">
					<UserProfilePage/>
				</Route>
				<Route path="/user-account">
					<UserAccount/>
				</Route>
				<Route path="/all">
					<Talents/>
				</Route>
				<Route path="/user-privacy">
					<UserPrivacy/>
				</Route>
				<Route path="/">
					<LoginPage/>
				</Route>
			</Switch>
		</BrowserRouter>);
}

export default App;