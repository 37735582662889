import {Box} from "@mui/material";
import UserCard from "./UserCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {styled} from "@mui/material/styles";

export function SampleNextArrow(props) {
	const {style, onClick} = props;
	return (<div
		className={'next-btn'}
		style={{...style, display: "block"}}
		onClick={onClick}>
		<ArrowForwardIosIcon fontSize={'large'}/>
	</div>);
}

export function SamplePrevArrow(props) {
	const {style, onClick} = props;
	return (<div
		className={'prev-btn'}
		style={{...style, display: "block"}}
		onClick={onClick}>
		<ArrowBackIosIcon fontSize={'large'}/>
	</div>);
}


const ExtendedBox = styled(Box)(({theme}) => ({
	maxWidth: '1450px', margin: '0 auto', paddingLeft: "80px",
	paddingRight: "80px", '@media (max-width: 550px)': {
		marginTop: '40px'
	}
}));

const UserList = (props) => {
	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 6,
		initialSlide: 0,
		nextArrow: <SampleNextArrow/>,
		prevArrow: <SamplePrevArrow/>,
		responsive: [ {
			breakpoint: 1450, settings: {
				slidesToShow: 4, slidesToScroll: 4, infinite: true, dots: true
			}
		}, {
			breakpoint: 1100, settings: {
				slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true
			}
		}, {
			breakpoint: 900, settings: {
				slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true
			}
		}, {
			breakpoint: 600, settings: {
				slidesToShow: 0, slidesToScroll: 1, initialSlide: 0, nextArrow: null, prevArrow: null,
			}
		}]
	};
	return (<ExtendedBox component={'div'}>
		<Slider {...settings}>
			{props.users.length > 0 && props.users?.map((user, index) => {
				return <Box class={'user-box'} component={'div'} item key={'userlist' + index}>
					<UserCard reCheck={props?.reCheck} user={user}/>
				</Box>
			})}
		</Slider>
	</ExtendedBox>)
}

export default UserList;
