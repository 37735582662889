import {Box, Grid} from "@mui/material";
import UserCard from "./UserCard";

const UserList = (props) => {
    return (<Grid container>
        {props.users.length > 0 && props.users?.map((user, index) => {
            return <Grid item key={'userlist' + index} xs={12} sm={6} md={4} lg={3} xl={2.4}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <UserCard user={user}/>
                </Box>
            </Grid>
        })}
    </Grid>)
}

export default UserList;
