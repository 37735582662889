import {Box, Container, Grid, IconButton, MenuItem} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import localforage from "localforage";
import {MenuButton, SubTxt} from "./UserSearch";
import MainRow from "./MainRow";
import {toast} from "react-toastify";
import axios from "axios";
import API from "../utils/api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "./Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableTemporaryDrawer from "./Drawer";

const HeadContainer = styled(Box)(({theme}) => ({
	width: '100%',
	height: 50, background: '#43a3b5', backgroundSize: '100%',
	paddingTop: '20px', '@media (max-width: 900px)': {
		paddingTop: '10px',

	},
}));
const LogoTxt = styled(Link)(({theme}) => ({
	color: 'white', textDecoration: 'none', fontFamily: 'Cherry Bomb', fontSize: '24px', position: 'relative'
}));

const MenuX = styled(Grid)(({theme}) => ({
	justifyContent: 'flex-end', alignItems: 'center', position: 'relative', top: '-8px', '@media (max-width: 900px)': {
		justifyContent: 'flex-start', marginTop: '20px'
	},
}));

const Talents = styled(Link)(({theme}) => ({
	paddingRight: 20,
	color: 'white',
	textDecoration: 'none',
	paddingLeft: 20,
	fontSize: '16px',
	borderRight: '1px solid white', // borderLeft: '1px solid white',
	fontWeight: 600
}));

const HeaderBlock = styled(Grid)(({theme}) => ({
	paddingRight: '10px', paddingLeft: '10px', cursor: 'pointer'
}));

const ContainerX = styled(Container)(({theme}) => ({
	maxWidth: '1550px !important',
}));

const HeaderContent = ({isTalentsActive, hasBackground = true}) => {
	const history = useHistory();
	const [username, setUsername] = useState('');
	const [profilePicture, setProfilePicture] = useState(null);
	const [menuList, setMenuList] = useState([]);
	const [open, setOpen] = useState({
		right: false,
	})

	useEffect(() => {
		getUserNameFromDatabase();
		getMenuList();
	}, []);

	const getMenuList = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.menus, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setMenuList(response.data.data)
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	const getUserNameFromDatabase = async () => {
		const user = await localforage.getItem('user');
		const userJSON = JSON.parse(user);
		setUsername(`${userJSON?.name} ${userJSON?.surname}`)
		setProfilePicture(userJSON?.profile?.url)
	}

	const openPage = (item) => {
		history.push(`/user-list/${item?.attributes?.title}`);
	}

	const redirectToHome = () => {
		history.push('/homepage')
	}


	const menuClickHandler = () => {
		setOpen({
			right: !open.right
		})
	}

	const redirectToProfile = () => {
		history.push('/user-account')
	}

	return (<HeadContainer sx={!hasBackground ? {backgroundColor: 'transparent'} : null}>
		<ContainerX item>
			<Grid container justifyContent={'space-between'}>
				<Grid xs={6} sm={6} md={4} lg={3}>
					<LogoTxt to="/homepage">Juicy Cloud <SubTxt component={'span'}>powered by
						Sharpcake</SubTxt></LogoTxt>
				</Grid>
				<MenuX xs={8} sm={6} md={8} lg={6} container
					   sx={{display: {xs: 'none', md: 'flex'}}}>
					{menuList?.map((menu) => {
						return (<div><Menu menu={menu} openPage={openPage}/></div>)
					})}
					<HeaderBlock onClick={redirectToProfile} sx={{display: {xs: 'none', sm: 'block'}}}
								 item>
						<Box sx={{height: '40px', width: '40px', borderRadius: '20px'}} component={'img'}
							 src={profilePicture} alt="profile"/>
					</HeaderBlock>
				</MenuX>
				<Grid sx={{position: 'relative', top: '-5px', display: {xs: 'block', md: 'none'}}} item xs={1} md={3}>
					<IconButton
						onClick={menuClickHandler}
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{mr: 2}}
					>
						<MenuIcon style={{color: 'white'}}/>
					</IconButton>
				</Grid>
			</Grid>
		</ContainerX>
		<SwipeableTemporaryDrawer sx={{display: {md: 'none'}}} state={open} setState={menuClickHandler}
								  menuList={menuList}/>
	</HeadContainer>)
}

export default HeaderContent;
