import HeaderContent from "../components/HeaderContent";
import {Box, Grid, Typography} from "@mui/material";
import AccountCard from "../components/Account/AccountCard";
import UserInfo from "../components/Account/UserInfo";
import React, {useEffect, useState} from "react";
import axios from "axios";
import API from "../utils/api";
import localforage from "localforage";
import {toast} from "react-toastify";
import {Link, useHistory} from "react-router-dom";
import MainRow from "../components/MainRow";
import Footer from "../components/FooterStatic";

const Breadcrumb = () => {
	return <Grid sx={{ marginTop: '50px'}}>
		<Link style={{textDecoration: 'none'}} to="/homepage">
			<Typography component={'span'}
						sx={{color: '#ABABAB', fontSize: '12px', fontFamily: 'Montserrat'}}>Home</Typography>
		</Link>
		<Link style={{textDecoration: 'none'}} to="/user-account">
			<Typography component={'span'}
						sx={{
							color: '#484848', fontSize: '12px', fontFamily: 'Montserrat', fontWeight: '500'
						}}>/Profile</Typography>
		</Link>
	</Grid>
}


const UserAccount = () => {
	const history = useHistory();
	const [user, setUser] = useState(null);

	useEffect(() => {
		getUserDataFromServer()
	}, []);

	const saveUser = async (currentUser) => {
		const token = await localforage.getItem('token');
		user.email = currentUser.email
		user.phone = currentUser.phone
		user.name = currentUser.name
		user.surname = currentUser.surname
		axios.put(API.userService + '/' + user.id, user, {
			headers: {
				'Authorization': token
			}
		}).then(() => {
			toast.success('Your profile has been updated');
		}).catch(() => {
			toast.error('An error occurred');
		})
	}

	const getUserDataFromServer = async () => {
		const token = await localforage.getItem('token');
		const userStr = await localforage.getItem('user');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const email = JSON.parse(userStr.toString()).email;
		axios.get(API.getMyUserDetail + email, {
			headers: {
				'Authorization': token
			}
		}).then((response) => {
			setUser(response.data[0]);
		}).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})
	}

	return <>
		<Grid container>
			<Grid item xs={12} maxWidth={'lg'}>
				<HeaderContent/>
			</Grid>
		</Grid>
		<MainRow>
			<Grid container>
				<Grid item>
					<Breadcrumb/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} md={4} lg={3}>
					<AccountCard user={user}/>
				</Grid>
				<Grid item xs={12} md={8} lg={9}>
					<UserInfo user={user} saveUser={saveUser}/>
				</Grid>
			</Grid>
		</MainRow>
		<Footer/>
	</>
}

export default UserAccount;
