import {Box, Button, Card, Grid, Rating, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";
import {Link, useHistory} from "react-router-dom";
import localforage from "localforage";

const UserBox = styled(Box)(({theme}) => ({



}));
const CardWrapper = styled(Card)(({theme}) => ({
	marginTop: 50, borderRadius: 20, padding: 0, overflow: 'visible',
	minHeight: '460px', background: '##FFFFFF',
}));

const UserContent = styled(Box)(({theme}) => ({
	display: 'flex', flexDirection: 'column', alignItems: 'center',
}));
const UserContentText = styled(Typography)(({theme}) => ({
	fontFamily: 'Montserrat',
	fontSize:'18px',
	'@media (max-width: 900px)': {
		fontSize:'16px',
	},
}))

const RowContent = styled(Box)(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop:'30px',
	paddingBottom:'30px',
	borderBottom: '1px solid #ededed',
	borderTop: '1px solid #ededed',

}));

const CustomLink = styled(Typography)(({theme}) => ({
	'& a': {
		textDecoration: 'none',
		fontFamily:'Montserrat',
		fontSize:'21px',
		color:'#7B7B7B',
		fontWeight:'500',
		'@media (max-width: 900px)': {
			fontSize:'16px',
		},
	}
}));



const AccountCard = (props) => {
	const {user} = props;
	const history = useHistory();

	const logout = async () => {
		await localforage.removeItem('token')
		await localforage.removeItem('user')
		await localforage.removeItem('searchItems')
		history.push("/");
	}


	return (<UserBox>
		<CardWrapper variant="outlined">
			<Grid sx={{display: 'flex', justifyContent: 'center', paddingTop:'38px'}}>
				<Box sx={{position: 'relative', width: '111px', height:'111px', borderRadius: '55px'}}
					 component={'img'} width={'70'} src={user?.profile?.url} alt=""/>
			</Grid>
			<UserContent mb={'38px'} mt={'38px'}>
				<UserContentText mb={'30px'} sx={{fontWeight: 500,fontSize:'24px',fontFamily:'Montserrat'}} variant={"h5"}>{user?.name} {user?.surname}</UserContentText>
				<UserContentText>{user?.phone}</UserContentText>
				<UserContentText>{user?.email}</UserContentText>
			</UserContent>
			<RowContent sx={{backgroundColor: history.location.pathname === '/user-account' ? '#ededed' : null}}>
				<CustomLink>
					<Link to={'/user-account'} style={{color:'black'}}>Profile</Link>
				</CustomLink>
			</RowContent>
			<RowContent sx={{backgroundColor: history.location.pathname === '/user-privacy' ? '#ededed' : null}}>
				<CustomLink>
					<Link to={'/user-privacy'}>Privacy</Link>
				</CustomLink>
			</RowContent>
			<RowContent >
				<CustomLink>
					<Link onClick={logout} to={'#'}>Log-out</Link>
				</CustomLink>
			</RowContent>
		</CardWrapper>
	</UserBox>);
}

export default AccountCard;
