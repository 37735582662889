import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";

const FooterWrapper = styled(Box)(({theme}) => ({
    height: '20px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '15px',
    background: '#44A3B5',
    textAlign: 'right',
    paddingRight: '25px',
    zIndex: 1000,
    '@media (max-width: 550px)': {
        display: 'none'
    },
}));

const Footer = () => {
    return <FooterWrapper>
        <Typography component="span" sx={{color: 'white', paddingRight: '30px'}}>
            All rights reserved © <Typography component="span" sx={{fontWeight: 'bold'}}>Sharpcake</Typography> 2023
        </Typography>
    </FooterWrapper>
}

export default Footer;