import {Box, Button, Card, Grid, Rating, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import React from 'react';
import JellyButton from "./JellyButton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Link} from "react-router-dom";
import capitalizeFirstLetter from "../utils/string";

const CardWrapper = styled(Card)(({theme}) => ({
	marginTop: 60, borderRadius: 40, padding: 0, overflow: 'visible', width: '100%', maxWidth: '400px', height: '600px',
}));

const RowContainer = styled(Box)(({theme}) => ({
	padding: '20px', borderTop: '0.5px solid #EDEDED',
}));

const UserTitle = styled(Typography)(({theme}) => ({
	fontWeight: 'bold', fontSize: '14px', fontFamily: 'Montserrat', '& a': {
		textDecoration: 'none'
	}
}));

const UserTitleText = styled(Typography)(({theme}) => ({
	fontFamily: 'Montserrat', fontSize: '14px', color: '#000000',
}));

const Rater = styled(Typography)(({theme}) => ({
	position: 'relative',
	right: '15px',
	top: '-14px',
	fontWeight: '500',
	fontSize: '12px',
	cursor: 'pointer',
	color: '#4B4B4B',
	'&>div': {
		position: 'absolute',
		display: 'none',
		left: '-240px',
		padding: '20px',
		background: '#ffffff',
		border: '1px solid #E9E9E9',
		borderRadius: '10px',
		bottom: '-150px',
		width: '300px'
	},
	'&:hover>div': {
		display: 'block'
	}
}));

const JellyButton2 = styled(Button)(({theme}) => ({
	background: '#44A3B5',
	height: '40px',
	fontSize: '16px',
	borderRadius: 10,
	textTransform: 'none',
	fontWeight: '800',
	boxShadow: 'none',
	' &:hover': {
		backgroundColor: '#44A3B5', boxShadow: 'none'
	}
}));

const UserProfileCard = (props) => {
	const {user} = props
	const handleSendEmail = () => {
		window.open(`mailto:${user.email}`, '_blank');
	}


	return (<Box sx={{justifyContent: 'center', paddingTop: '40px'}}>
		<CardWrapper
			sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}
			variant="outlined">
			<Box>
				<Grid sx={{display: 'flex', justifyContent: 'center'}}>
					<Box
						sx={{
							position: 'relative',
							top: '-50px',
							width: '100px',
							height: '100px',
							borderRadius: '50px',
							objectFit: 'cover'
						}}
						component={'img'} width={'70'} src={user?.profile?.url} alt=""/>
				</Grid>
				<RowContainer>
					<UserTitleText sx={{fontSize: '16px', textAlign: 'center', position: 'relative', top: '-50px'}}
								   variant={"h6"}>{user?.name} {user?.surname}</UserTitleText>
				</RowContainer>
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<UserTitle sx={{position: 'relative', left: '20px', bottom: '20px'}} component={'span'}>Rate:<Rating
						name="read-only"
						sx={{
							position: 'relative', top: '7px', left: '10px'
						}}
						value={parseFloat(user?.rate)}
						precision={0.1}
						readOnly/>
					</UserTitle>
					<Rater component={'span'}>See rate chart
						<Box>
							<Box>
								<UserTitle component={'span'}>
									<Typography
										sx={{display: 'inline-block', width: '150px', fontSize: '14px'}}>On
										time delivery</Typography>:<Rating
									name="read-only"
									sx={{
										position: 'relative', top: '7px', left: '10px'
									}}
									value={parseFloat(user?.on_time_delivery)}
									precision={0.1}
									readOnly/>
								</UserTitle>
							</Box>
							<Box>
								<UserTitle component={'span'}>
									<Typography
										sx={{display: 'inline-block', width: '150px', fontSize: '14px'}}>Quality
										of delivery</Typography>:<Rating
									name="read-only"
									sx={{
										position: 'relative', top: '7px', left: '10px'
									}}
									value={parseFloat(user?.quality_of_delivery)}
									precision={0.1}
									readOnly/>
								</UserTitle>
							</Box>
							<Box>
								<UserTitle component={'span'}>
									<Typography sx={{display: 'inline-block', width: '150px', fontSize: '14px'}}>Communication
										skills</Typography>:<Rating
									name="read-only"
									sx={{
										position: 'relative', top: '7px', left: '10px'
									}}
									value={parseFloat(user?.communication_skills)}
									precision={0.1}
									readOnly/>
								</UserTitle>
							</Box>
						</Box>
					</Rater>
				</Box>
				<RowContainer>
					<UserTitle component={'span'}>Languages :</UserTitle>
					<UserTitleText component={'span'}> {user?.languages?.map((language, index) => {
						return <UserTitleText key={'row' + index}
											  component={'span'}>{index !== 0 ? ',' : ''} {language?.title}</UserTitleText>
					})}</UserTitleText>
				</RowContainer>

				{user?.rates?.map((rate, index) => {
					return (<RowContainer sx={{justifyContent: 'space-between', display: 'flex'}}>
						<UserTitle component={'span'}>
							{index === 0 && 'Skills:'}
							<UserTitleText sx={index !== 0 ? {paddingLeft: '55px'} : {paddingLeft: '15px'}}
										   key={'profile' + index}
										   component={'span'}>
								{rate?.skill?.title}
							</UserTitleText>
						</UserTitle>
						<UserTitle component={'span'}>
							{index === 0 && 'Rate:'}
							<UserTitleText sx={index !== 0 ? {paddingLeft: '55px'} : {paddingLeft: '15px'}}
										   key={'profile' + index} component={'span'}>
								<Typography component={'span'} sx={{fontWeight: 'bold'}}>
									${rate?.rate}</Typography>/per
								hour</UserTitleText>
						</UserTitle>
					</RowContainer>)
				})}
				<RowContainer>
					<UserTitle component={'span'}>Next day delivery : <UserTitleText
						component={'span'}>{user?.ndd === true ? "Yes" : "No"}</UserTitleText></UserTitle>
				</RowContainer>
				<RowContainer>
					<UserTitle component={'span'}>Whatsapp : <UserTitleText
						component={'span'}>{user?.whatsapp}</UserTitleText>
						<Link target={'_blank'} to={{pathname: `whatsapp://send?phone=${user?.whatsapp}`}}>
							<UserTitleText component={'span'} sx={{
								paddingLeft: '10px', textDecoration: 'none', position: 'relative', left: '20px'
							}}><Box component={'span'} sx={{
								position: 'relative', top: '8px', color: '#797979'
							}}><WhatsAppIcon sx={{width: '16px'}}/></Box> Open Whatsapp</UserTitleText>
						</Link>
					</UserTitle>
				</RowContainer>
			</Box>
			<JellyButton2
				type="submit"
				onClick={handleSendEmail}
				fullWidth
				variant="contained"
				sx={{
					fontFamily: 'Montserrat',
					fontSize: '12px',
					width: 'calc(100% + 2px)',
					height: '60px',
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px',
					mt: '5px',
					ml: '-1px',
					background: '#44A3B5',
				}}>
				Contact Via E-Mail
			</JellyButton2>
		</CardWrapper>
	</Box>);
}

export default UserProfileCard;
