import LoginImage from "../components/LoginImage";
import {Grid} from "@mui/material";
import UserMail from "../components/UserMail";
import {useEffect, useState} from "react";
import axios from "axios";
import API from "../utils/api";

const ForgetPassword = () => {
    const [forgetPassword, setForgetPassword] = useState(null);

    useEffect(() => {
        getForgetPassword()
    }, []);

    const getForgetPassword = async () => {
        const response = await axios.get(API.forget);
        if (response.status === 200) {
            setForgetPassword(response.data.data[0]);
        }
    }

    return <Grid container>
        <Grid item xs={12}  lg={6}>
            <LoginImage welcome={forgetPassword}/>
        </Grid>
        <Grid item xs={12} lg={6}>
            <UserMail welcome={forgetPassword}/>
        </Grid>
    </Grid>
}

export default ForgetPassword;
