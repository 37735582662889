import {Box, Card, Fab, Grid, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {useHistory} from "react-router-dom";
import ReactCurvedText from "react-curved-text";
import axios from "axios";
import API from "../utils/api";
import localforage from "localforage";
import {toast} from "react-toastify";

const UserSkilItem = styled(Typography)(({theme}) => ({
	paddingLeft: '20px', fontFamily: 'Montserrat', fontSize: '16px',
}));

const UserSkillTitle = styled(Typography)(({theme}) => ({
	fontWeight: 'bold', fontFamily: 'Montserrat', fontSize: '12px',
}));

const UserInfo = styled(Typography)(({theme}) => ({
	fontFamily: 'Montserrat', fontSize: '12px',

}));

const UserCardWrapper = styled(Card)(({theme}) => ({
	position: 'relative',
	paddingTop: '80px',
	overflow: 'visible',
	paddingBottom: '20px',
	cursor: 'pointer',
	minHeight: '120px',
	border: '1px solid #E9E9E9',
	borderRadius: '10px',
	boxShadow: 'none',
	'@media (min-width: 1100px)': {
		minHeight: '150px'
	},
	'@media (min-width: 1200px)': {
		minHeight: 'auto'
	}
}));

const ProfileImg = styled(Box)(({theme}) => ({
	fontWeight: 'bold',
	position: 'absolute',
	top: '-50px',
	right: 0,
	left: 0,
	margin: '0 auto',
	width: '112px',
	height: '112px',
	borderRadius: '64px',
	objectFit: 'cover'
}));

const BadgeText = styled(Box)(({theme}) => ({
	position: 'absolute',
	top: '64px',
	width: '100%',
	textAlign: 'center',
	fontSize: '9px',
	color: '#ffffff',
	fontWeight: 'bold'
}));

const BadgeTextSuccess = styled(Box)(({theme}) => ({
	position: 'absolute',
	top: '70px',
	textAlign: 'center',
	fontSize: '10px',
	color: '#ffffff',
	fontWeight: 'bold',
	background: '#44A3B5',
	borderRadius: '4px',
	width: '45px',
	lineHeight: '17px'
}));

const BadgeTextFail = styled(Box)(({theme}) => ({
	position: 'absolute',
	top: '64px',
	textAlign: 'center',
	fontSize: '10px',
	color: '#ffffff',
	fontWeight: 'bold',
	background: '#44A3B5',
	borderRadius: '4px',
	width: '45px',
	lineHeight: '17px'
}));

const UserCard = (props) => {
	const {user} = props;
	const history = useHistory();

	let userNameAndSurname = `${user?.name} ${user?.surname}`;
	if (userNameAndSurname.length > 16) {
		userNameAndSurname = userNameAndSurname.substring(0, 16) + '...';
	}

	const favoriteIconStyles = {
		fontWeight: 'bold',
		position: 'absolute',
		top: '10px',
		right: '5px',
		bottom: '35%px',
		color: user?.isFavorite ? '#44A3B5' : '#ededed'
	}

	const handleCardClick = () => {
		history.push(`/user-profile/${user?.id}`);
	}

	const addToFavorite = async (e) => {
		e.preventDefault()
		e.stopPropagation();
		const token = await localforage.getItem('token');
		user.isFavorite = !user?.isFavorite;
		axios.put(API.updateUser + user?.id, user, {
			headers: {
				'Authorization': token
			}
		}).then((response) => {
			props?.reCheck()
		}).catch((e) => {
			if (e?.response?.status === 401) {
				localforage.removeItem('token');
				history.push("/");
			}
		})
	}

	return (<Box sx={{justifyContent: 'center', marginTop: '70px', padding: '20px'}}>
		<UserCardWrapper onClick={handleCardClick}>
			<ProfileImg component="img" width="60" src={user?.profile?.url || 'images/placeholder.png'} alt="profile"/>
			<Box sx={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
				{user?.status === 'INPROGRESS' && <BadgeTextFail>#Busy</BadgeTextFail>}
				{user?.status === 'DONE' && <BadgeTextSuccess>#Open</BadgeTextSuccess>}
				{user?.status === 'OPEN' && <BadgeTextSuccess>#Open</BadgeTextSuccess>}
				{!user?.status && <BadgeTextSuccess>#Open</BadgeTextSuccess>}
			</Box>
			<FavoriteIcon onClick={addToFavorite} sx={favoriteIconStyles}/>
			<Grid container mt={2} justifyContent={'space-between'} sx={{fontFamily: 'Avenir', fontSize: '12px'}}>
				<Grid item>
					<UserSkilItem sx={{fontSize: '16px', fontWeight: 500}} component="p">
						{userNameAndSurname}
					</UserSkilItem>
				</Grid>
				<Grid item sx={{color: '#44A3B5', position: 'relative', top: '5px', right: '10px'}}>
					<UserSkilItem component="p" sx={{
						fontFamily: 'Montserrat', fontSize: '12px',
					}}>
						{/*{user?.rate || '-'}/5*/}
					</UserSkilItem>
				</Grid>
			</Grid>
			<UserSkilItem mt={'10px'} component="p" sx={{paddingRight: '10px', minHeight:'48px'}}>
				<UserSkillTitle component="span">Languages: </UserSkillTitle>
				{user?.languages?.length > 0 && user?.languages?.map((language, index) => {
					return <UserInfo key={'language' + index}
									 component={'span'}>{index !== 0 ? ',' : ''} {language?.title}</UserInfo>
				})}
			</UserSkilItem>
			<UserSkilItem component="p" sx={{ minHeight:'48px', marginTop:'10px'}}>
				<UserSkillTitle component="span">Skils: </UserSkillTitle>
				{user?.rates?.length > 0 && user?.rates?.map((rate, index) => {
					return <UserInfo key={'skill' + index}
									 component={'span'}>{index !== 0 ? ',' : ''} {rate?.skill?.title}</UserInfo>
				})}
			</UserSkilItem>
			<UserSkilItem component="p" sx={{
				fontFamily: 'Montserrat', fontSize: '12px', marginTop: '5px'
			}}>
				<UserSkillTitle component="span">NDD: </UserSkillTitle>
				{user?.ndd === true ? "Yes" : "No"}
			</UserSkilItem>
		</UserCardWrapper>
	</Box>)
}

export default UserCard;
