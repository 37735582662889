import {Box, Button, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import React, {useEffect, useState} from "react";
import localforage from "localforage";
import {toast} from "react-toastify";
import axios from "axios";
import API from "../utils/api";
import Menu from "./Menu";
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableTemporaryDrawer from "./Drawer";
import HeaderContent from "./HeaderContent";
import getCdnImage from "../utils/image";

const LogoTxt = styled(Link)(({theme}) => ({
	color: 'white',
	textDecoration: 'none',
	fontFamily: 'Cherry Bomb',
	fontSize: '24px',
	position: 'relative',
	'@media (max-width: 750px)': {
		paddingLeft: 0, paddingRight: 0
	},
}));

export const Talents = styled(Link)(({theme}) => ({
	paddingRight: 20,
	color: 'white',
	textDecoration: 'none',
	marginRight: '20px',
	fontWeight: 'bold',
	fontSize: '14px',
	position: 'relative',
	'&:active, &:hover': {
		textDecoration: 'underline', fontWeight: 'bolder'
	},
	'&:before': {
		position: 'absolute', left: '-25px', top: '0px', content: '""', width: '20px', height: '20px', // backgroundImage: 'url(images/talents.svg)',
		backgroundSize: '100%'
	}
}));
export const MenuButton = styled(Button)(({theme}) => ({
	color: 'white', textTransform: 'capitalize', fontWeight: 'bold', fontSize: '14px', marginRight: '20px',
}));


const HeadContainer = styled(Grid)(({theme}) => ({
	height: 400, backgroundSize: '100%', '@media (max-width: 990px)': {
		objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'auto 100%'
	}
}));

const NoWorriesTxt1 = styled(Typography)(({theme}) => ({
	color: 'white', fontFamily: 'Lilita One', fontWeight: 'normal', fontSize: '30px', '@media (max-width: 990px)': {
		fontSize: '20px',
	}
}));

const NoWorriesTxt2 = styled(Typography)(({theme}) => ({
	color: 'white', fontFamily: 'Lilita One', fontWeight: 'normal', fontSize: '40px', '@media (max-width: 990px)': {
		fontSize: '25px',
	}
}));


const SearchTextField = styled(TextField)(({theme}) => ({
	backgroundColor: 'white', width: '900px', borderRadius: '30px', '& input': {
		height: '50px'
	}, '& > div:before': {
		borderBottom: '0 !important'
	}, '@media (max-width: 1000px)': {
		width: '500px',
	}, '@media (max-width: 550px)': {
		width: '100%',
	}
}));

const Tag = styled(Typography)(({theme}) => ({
	color: 'white',
	backgroundColor: '#0B7A90E5',
	borderRadius: '10px',
	marginRight: '15px',
	display: 'inline-block',
	paddingLeft: '20px',
	paddingRight: '20px',
	cursor: 'pointer',
	height: '18px',

}));

export const SubTxt = styled(Typography)(({theme}) => ({
	color: 'white',
	position: 'absolute',
	top: '25px',
	right: '-18px',
	fontSize: '11px',
	fontWeight: '400',
	fontFamily: 'Cambay',
	display: 'inline-block',
	width: '125px'
}));

const SearchResultGrid = styled(Grid)(({theme}) => ({
	justifyContent: 'flex-end', alignItems: 'center', '@media (max-width: 900px)': {
		justifyContent: 'flex-start', marginTop: '20px'
	}
}));

const ExtendedGrid = styled(Grid)(({theme}) => ({
	paddingLeft: '50px', paddingRight: '50px', '@media (max-width: 900px)': {
		paddingLeft: 0, paddingRight: 0,
	},
}));

const MenuX = styled(Grid)(({theme}) => ({
	justifyContent: 'flex-end', alignItems: 'center', '@media (max-width: 900px)': {
		justifyContent: 'flex-start', marginTop: '20px'
	},
}));

const GridY = styled(Grid)(({theme}) => ({
	marginTop: '100px', '@media (max-width: 900px)': {
		marginTop: '50px',
	},
}));
const GridProfile = styled(Grid)(({theme}) => ({
	'@media (max-width: 900px)': {
		marginLeft: '0px'
	},
}));


const UserSearch = (props) => {
	const {setSearchEnabled, setSearchValue, handleKeyDown, skillsList, menuList} = props;
	const history = useHistory();
	const [popularskills, setPopularSkills] = useState([]);
	const [username, setUsername] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);
	const [_searchValue, _setSearchValue] = useState({
		title: ''
	});

	const [open, setOpen] = useState({
		right: false,
	})

	useEffect(() => {
		getPopularSkillsFromServer();
		getUserNameFromDatabase();
	}, []);


	const onSearchChanged = (e) => {
		_setSearchValue({
			title: e.target.value
		})
	}

	const getUserNameFromDatabase = async () => {
		const user = await localforage.getItem('user');
		const userJSON = JSON.parse(user);
		setUsername(`${userJSON?.name} ${userJSON?.surname}`)
		setProfilePicture(userJSON?.profile?.url)
	}

	const getPopularSkillsFromServer = async () => {
		const token = await localforage.getItem('token');
		if (!token) {
			toast('lütfen login olunuz.');
			history.push("/");
			return false;
		}
		const response = await axios.get(API.skills, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			setPopularSkills(response.data.data.splice(0, 3))
		} else {
			toast.error('Bir hata oluştu')
		}
	}

	const onFocusHandle = () => {
		setSearchEnabled(true)
	}

	const searchPopular = (searchParams) => {
		setSearchValue(searchParams);
	}

	const onBlurHandle = () => {
		setTimeout(() => {
			setSearchEnabled(false);
		}, 500)
	}

	const redirectUserAccount = () => {
		history.push('user-account')
	}

	const openPage = (item) => {
		history.push(`/user-list/${item?.attributes?.title}`);
	}


	const menuClickHandler = () => {
		setOpen({
			right: !open.right
		})
	}

	return (<HeadContainer sx={{backgroundImage: `url(${props?.homepageMessage?.attributes?.image?.data?.attributes?.url})`}}>
		<Grid xs={12}>
			<HeaderContent hasBackground={false}/>
		</Grid>
		<Grid container justifyContent={'center'}>
			<GridY item>
				<NoWorriesTxt1 component="h1" variant="h4">
					{props?.homepageMessage?.attributes?.title}
				</NoWorriesTxt1>
				<NoWorriesTxt2 component="h1" variant="h6">
					{props?.homepageMessage?.attributes?.description}
				</NoWorriesTxt2>
				<SearchTextField
					id={'user-search'}
					sx={{position: 'relative', zIndex: 12, paddingLeft: '10px'}}
					InputProps={{
						startAdornment: (<InputAdornment position="start">
							<SearchIcon/>
						</InputAdornment>), readOnly: false
					}}
					margin="normal"
					onFocus={onFocusHandle}
					onKeyDown={(e) => handleKeyDown(e, _searchValue)}
					value={_searchValue.title}
					readOnly={false}
					autoComplete='off'
					onBlur={onBlurHandle}
					onChange={onSearchChanged}
					fullWidth
					variant="standard"
					name="email"
				/>
				<SearchResultGrid container>
					<Grid item className={'hide-mobile'}>
						{popularskills?.length > 0 && <Typography component="span" sx={{
							fontFamily: 'Montserrat',
							color: 'white',
							mr: '10px',
							display: 'inline-block',
							fontSize: '14px',
							fontWeight: '500'
						}}>
							What are you looking for?
						</Typography>}
					</Grid>
					{popularskills.map((skill, index) => {
						return <Grid item key={'popularskill' + index}>
							<Tag sx={{
								fontSize: '12px',
								fontFamily: 'Montserrat',
								paddingTop: '7px',
								paddingLeft: '10px',
								paddingRight: '7px',
								paddingBottom: '10px'
							}}
								 onClick={() => searchPopular({title: skill.attributes.title, type: 'skills'})}
								 component="span">
								{skill.attributes.title}
							</Tag>
						</Grid>
					})}
				</SearchResultGrid>
			</GridY>
		</Grid>
		<SwipeableTemporaryDrawer state={open} setState={menuClickHandler} menuList={menuList}/>
	</HeadContainer>)
}
export default UserSearch;
