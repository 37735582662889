import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";

const MainRow = styled(Box)(({theme}) => ({
	maxWidth: '1500px',
	margin: '0 auto',
	paddingLeft:'20px',
	paddingRight:'20px',
	'@media (max-width: 1499)': {
		maxWidth: '1280px',
	},
	'@media (max-width: 1399)': {
		maxWidth: '1180px',
	},
	'@media (max-width: 120px)': {
		maxWidth: '1100px', paddingLeft: '30px', paddingRight: '30px'
	},
	'@media (max-width: 900px)': {
		maxWidth: '100%', paddingLeft: '30px', paddingRight: '30px'
	}
}));



export default MainRow;
