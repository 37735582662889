import {Grid} from "@mui/material";
import HeaderContent from "../components/HeaderContent";
import {useParams} from "react-router-dom";
import React from "react";
import UserTalentsContent from "../components/UserTalentsContent";
import MainRow from "../components/MainRow";
import Footer from "../components/FooterStatic";


const Talents = () => {
	let {type, search} = useParams();

	return <Grid container>
		<Grid xs={12}>
			<HeaderContent isTalentsActive={true}/>
		</Grid>
		<Grid xs={12}>
			<MainRow>
				<UserTalentsContent type={type} search={search}/>
			</MainRow>
		</Grid>
		<Footer/>
	</Grid>
}
export default Talents;
