import {Box, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {styled} from "@mui/material/styles";
import JellyButton from "./JellyButton";
import validateEmail from "../utils/email";
import API from "../utils/api";
import {LoginBox} from "./UserLogin";

const LoginTextField = styled(TextField)(({theme}) => ({
	'@media (max-width: 550px)': {
		width: '300px',
	}, ' & fieldset': {
		border: '1px solid #44A3B5', boxSizing: 'border-box', borderRadius: '15px', '@media (max-width: 550px)': {}
	}
}));


const Box2 = styled(Box)(({theme}) => ({
	width: '480px', '@media (max-width: 550px)': {
		width: '300px',
	}
}));


const UserEmail = (props) => {

	const {welcome} = props;
	const [email, setEmail] = useState('');

	const emailChanged = (event) => {
		setEmail(event.target.value);
	}

	const clickHandler = () => {
		if (!validateEmail(email)) {
			toast.error('Please enter a valid e-mail')
			return false;
		}
		makeSendPasswordToEmailRequest()
	}

	const makeSendPasswordToEmailRequest = () => {
		axios.post(API.forgetPassword, {
			email: email
		}).then((response) => {
			if (response?.status === 200) {
				toast.success('Your password reset email has been sent.')
			} else {
				toast.error('Email is not correct')
			}
		}).catch(() => {
			toast.error('Email is not correct')
		})
	}

	return (<LoginBox>
		<Box component="div" noValidate sx={{mt: 1}}>
			<Typography component="h1" variant="h3"
						sx={{fontFamily: 'Lilita One', fontWeight: 'normal', textAlign: 'left'}}>
				{welcome?.attributes?.title}
			</Typography>
			<Box2 component={'p'}> {welcome?.attributes?.description} </Box2>
			<LoginTextField
				margin="normal"
				required
				fullWidth
				id="email"
				value={email}
				onChange={emailChanged}
				label="E-mail"
				name="email"
				autoComplete="email"
				autoFocus
			/>

			<JellyButton
				type="button"
				fullWidth
				onClick={clickHandler}
				variant="contained"
				sx={{mt: 3, mb: 2}}>
				Login
			</JellyButton>
		</Box>
	</LoginBox>)
}
export default UserEmail;
